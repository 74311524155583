import { useState, useEffect } from 'react';
import { fetchStores } from '../api/NCDApi';

export const useStores = (silos: string[], itemsPerPage: number) => {
  const [storesData, setStoresData] = useState<Record<string, string[]>>({});
  const [filteredStoresData, setFilteredStoresData] = useState<Record<string, string[]>>({});

  useEffect(() => {
    const fetchStoresInBatches = async () => {
      const siloBatches: string[][] = [];
      for (let i = 0; i < silos.length; i += itemsPerPage) {
        siloBatches.push(silos.slice(i, i + itemsPerPage));
      }
      for (const batch of siloBatches) {
        const batchPromises = batch.map(async (silo) => {
          const stores = await fetchStores(silo);
          return { silo, stores };
        });
        const batchResults = await Promise.all(batchPromises);
        const batchMap = batchResults.reduce((acc, { silo, stores }) => {
          acc[silo] = stores;
          return acc;
        }, {});
        setStoresData((prevData) => {
          const newData = { ...prevData, ...batchMap };
          setFilteredStoresData(newData);
          return newData;
        });
      }
    };

    if (silos.length > 0) {
      fetchStoresInBatches();
    }
  }, [silos, itemsPerPage]);

  return { storesData, filteredStoresData, setFilteredStoresData };
};