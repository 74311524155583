import React, { useState } from 'react';
import Container from "@amzn/awsui-components-react/polaris/container";
import { fetchSilos, fetchCurrentCommitTracking } from '../api/NCDApi';
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import { getLogger } from 'src/utils/logger';
import { DeployedConfig, TrackingDataBySilo, AccordionProps } from '../types';

const logger = getLogger();



const Accordion: React.FC<AccordionProps> = ({ headerText, onChange, trackingData, expanded }) => {
  return (
    <div>
      <ExpandableSection variant="container" headerText={headerText} onChange={onChange} expanded={expanded}>
        <Table
          columnDefinitions={[
            {
              id: "ncg_commit_id",
              header: "NCG Commit ID",
              sortingField: "ncg_commit_id",
              cell: (item) => item.ncg_commit_id || "-"
            },
            {
              id: "start_time",
              header: "Start Time",
              cell: (item) => item.start_time || "-",
              sortingField: "start_time",
            },
            {
              id: "store_id",
              header: "Store ID",
              cell: (item) => item.store_id || "-",
              sortingField: "store_id",
            },
          ]}
          items={trackingData}
          loadingText="Loading deployment history"
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No deployment history found</b>
                <Button>Refresh</Button>
              </SpaceBetween>
            </Box>
          }
        />
      </ExpandableSection>
      <br />
    </div>
  );
};

const LatestCommitPage: React.FC = () => {
  const [silos, setSilos] = useState<string[]>([]);
  const [trackingData, setTrackingData] = useState<TrackingDataBySilo>({});
  const [expandedSilo, setExpandedSilo] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  React.useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const [silosData, fetchedTrackingData] = await Promise.all([
          fetchSilos(),
          fetchCurrentCommitTracking()
        ]);

        if (silosData) {
          setSilos(silosData);
        }

        if (fetchedTrackingData) {
          setTrackingData(fetchedTrackingData);
          logger.log('Current Commit Tracking Data:', fetchedTrackingData);
        } else {
          logger.error('Failed to fetch current commit tracking data');
        }
      } catch (error) {
        logger.error('Error fetching initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleAccordionClick = (silo: string) => {
    setExpandedSilo(expandedSilo === silo ? null : silo);
  };

  const getTrackingDataForSilo = (silo: string): DeployedConfig[] => {
    return trackingData[silo]?.data ?? [];
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <div>
        {silos.map((silo, index) => (
          <Accordion
            key={index}
            headerText={silo}
            onChange={() => handleAccordionClick(silo)}
            trackingData={getTrackingDataForSilo(silo)}
            expanded={expandedSilo === silo}
          />
        ))}
      </div>
    </Container>
  );
};



export default LatestCommitPage;
