import { useState, useEffect } from 'react';
import { StoreData, CommitTrackingData } from '../types';

export const useCommitTracking = (commitTrackingData: CommitTrackingData | null) => {
    const [processedCommitTrackingData, setProcessedCommitTrackingData] = useState<{
      [storeId: string]: StoreData;
    }>({});
  
    useEffect(() => {
      if (commitTrackingData) {
        const processedData: { [storeId: string]: StoreData } = {};
        Object.entries(commitTrackingData).forEach(([siloId, siloData]) => {
          if (siloData.data) {
            siloData.data.forEach(config => {
              const { ncg_commit_id, start_time, store_id } = config;
              const commitId = ncg_commit_id || "UNKNOWN";
              const commitTimeEpoch = new Date(start_time).getTime();
              processedData[store_id] = { commitId, commitTimeEpoch };
            });
          }
        });
        setProcessedCommitTrackingData(processedData);
      }
    }, [commitTrackingData]);
  
    return processedCommitTrackingData;
  };
  