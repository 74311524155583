import * as React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import { fetchSilos, fetchStores, fetchDeploymentHistory } from '../api/NCDApi';
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import { getLogger } from 'src/utils/logger';

const logger = getLogger();

interface DeploymentHistoryItem {
  deployment_status: string;
  end_time: string;
  ncg_commit_id: string;
}

export default function DeploymentHistoryPage() {
  const [loader, setLoader] = React.useState(false);
  const [silos, setSilos] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [deploymentHistory, setDeploymentHistory] = React.useState<DeploymentHistoryItem[]>([]);
  const [selectedSilo, setSelectedSilo] = React.useState('');
  const [selectedStore, setSelectedStore] = React.useState('');

  React.useEffect(() => {
    fetchSilosData();
  }, []);

  React.useEffect(() => {
    if (selectedSilo) {
      fetchStoreData(selectedSilo);
    }
  }, [selectedSilo]);

  const fetchSilosData = async () => {
    const silosData = await fetchSilos();
    if (silosData) {
      setSilos(silosData);
    }
  };

  const fetchStoreData = async (selectedSilo) => {
    const storesData = await fetchStores(selectedSilo);
    if (storesData) {
      setStores(storesData);
    }
  };

  const handleSiloChange = (silo_id) => {
    setSelectedSilo(silo_id.value);
    setSelectedStore('');
    setDeploymentHistory([]);
  };

  const handleStoreChange = (key) => {
    setSelectedStore(key.value);
  };

  const fetchDeploymentHistoryData = async () => {
    if (selectedStore) {
      setLoader(true);
      const deploymentHistoryData = await fetchDeploymentHistory(selectedStore);
      if (deploymentHistoryData) {
        setDeploymentHistory(deploymentHistoryData);
        logger.log('Deployment History:', deploymentHistoryData);
      } else {
        logger.error('Failed to fetch deployment history');
        setDeploymentHistory([]);
      }
      setLoader(false);
    }
  };

  return (
    <Container>
      <SpaceBetween size="l">
        <SpaceBetween size="m">
          <FormField constraintText="Required" label="Select a Silo">
            <Select
              ariaLabel="Select a Silo"
              options={silos.map((silo) => ({ label: silo, value: silo }))}
              selectedOption={{label: selectedSilo, value: selectedSilo}}
              onChange={({ detail }) => handleSiloChange(detail.selectedOption)}
            />
          </FormField>
          <FormField constraintText="Required" label="Select a Store">
            <Select
              ariaLabel="Select a Store"
              options={stores.map((store) => ({ label: store, value: store }))}
              selectedOption={{label: selectedStore, value: selectedStore}}
              onChange={({ detail }) => handleStoreChange(detail.selectedOption)}
            />
          </FormField>
          <Button onClick={fetchDeploymentHistoryData} disabled={!selectedStore}>
            Load Deployment History
          </Button>
        </SpaceBetween>
        
        <Table
          loading={loader}
          loadingText="Loading deployment history"
          columnDefinitions={[
            {
              id: "deployment_status",
              header: "Deployment Status",
              cell: (item) => item.deployment_status,
              sortingField: "deployment_status",
              width: "25%",
              minWidth: 165,
            },
            {
              id: "end_time",
              header: "End Time",
              cell: (item) => item.end_time,
              sortingField: "end_time",
              width: "25%",
              minWidth: 110,
            },
            {
              id: "ncg_commit_id",
              header: "NCG Commit ID",
              cell: (item) => item.ncg_commit_id,
              sortingField: "ncg_commit_id",
              width: "25%",
              minWidth: 170,
            },
          ]}
          items={deploymentHistory}
          resizableColumns
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No deployment history found</b>
                <Button onClick={fetchDeploymentHistoryData} disabled={!selectedStore}>Refresh</Button>
              </SpaceBetween>
            </Box>
          }
        />
      </SpaceBetween>
    </Container>
  );
}
