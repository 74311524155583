import axios from 'axios';
import { getLogger } from 'src/utils/logger';
import { Silo, DeployedConfig, TrackingDataResponse, TrackingDataBySilo } from '../types';

const logger = getLogger();

const domain = process.env.REACT_APP_STAGE || 'alpha';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchSilos = async () => {
  if (!API_BASE_URL) {
    logger.error('API_BASE_URL is not defined in the environment');
    return null;
  }

  const url = `${API_BASE_URL}/getSiloList`;
  const requestData = { domain: domain };

  try {
    const response = await axios.post(url, requestData);

    if (response.status >= 200 && response.status < 300) {
      const siloList = response.data.map(item => item.silo_id);
      return siloList;
    } else {
      logger.error('Error fetching silos:', response.status, response.data);
      return null;
    }
  } catch (error) {
    logger.error('Error fetching silos:', error);
    return null;
  }
};

export const fetchStores = async (silo_id: string) => {
  if (!API_BASE_URL) {
    logger.error('API_BASE_URL is not defined in the environment');
    return null;
  }

  const url = `${API_BASE_URL}/getStoresList`;
  const requestData = { domain, silo_id };

  try {
    const response = await axios.post(url, requestData);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      logger.error('Error fetching stores:', response.status, response.data);
      return null;
    }
  } catch (error) {
    logger.error('Error fetching stores:', error);
    return null;
  }
};

export const fetchDeploymentHistory = async (storeId: string) => {
  if (!API_BASE_URL) {
    logger.error('API_BASE_URL is not defined in the environment');
    return null;
  }

  const url = `${API_BASE_URL}/getDeploymentHistory`;
  const requestData = { store_id: storeId };

  try {
    const response = await axios.post(url, requestData);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      logger.error('Error fetching deployment history:', response.status, response.data);
      return null;
    }
  } catch (error) {
    logger.error('Error fetching deployment history:', error);
    return null;
  }
};

const getSiloList = async (): Promise<Silo[]> => {
  if (!API_BASE_URL) {
    logger.error('API_BASE_URL is not defined in the environment');
    return [];
  }

  const url = `${API_BASE_URL}/getSiloList`;
  try {
    const response = await axios.post(url, { domain: domain });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (error) {
    logger.error('Error fetching silo list:', error);
  }
  return [];
};

const getApiEndpoint = (siloId: string, region: string): string => {
  const stage = process.env.REACT_APP_STAGE;
  if (stage === 'prod') {
    return `https://${siloId.toLowerCase()}.${region}.ncds.jihm.amazon.dev/`;
  } else {
    const cell = process.env.REACT_APP_CELL || '';
    return `https://${cell.toLowerCase()}.${region}.${stage}.ncds.jihm.amazon.dev/`;
  }
};

export const fetchCurrentCommitTracking = async (): Promise<TrackingDataBySilo | null> => {
  if (!API_BASE_URL) {
    logger.error('API_BASE_URL is not defined in the environment');
    return null;
  }

  const isDev = process.env.REACT_APP_DEV_ENVIRONMENT === 'true';
  let silos: Silo[] = [];
  let endpoints: string[] = [];

  if (isDev) {
    silos = [{ silo_id: 'ASSET-IAD1', region: 'local' }];
    endpoints = [`${API_BASE_URL}/trackCommit`];
  } else {
    silos = await getSiloList();
    endpoints = silos.map(silo => `${getApiEndpoint(silo.silo_id, silo.region)}v1/trackCommit`);
  }

  let trackingDataBySilo: TrackingDataBySilo = {};

  for (let i = 0; i < endpoints.length; i++) {
    const endpoint = endpoints[i];
    const silo = silos[i];
    let requestData = {};
    let lastEvaluatedKey: string | null = null;
    let trackingData: DeployedConfig[] = [];

    try {
      do {
        if (lastEvaluatedKey) {
          requestData = { last_evaluated_key: lastEvaluatedKey };
        }
        const response = await axios.post<TrackingDataResponse>(endpoint, requestData);

        if (response.status >= 200 && response.status < 300) {
          trackingData = trackingData.concat(response.data.deployed_configs);
          lastEvaluatedKey = response.data.last_evaluated_key;
        } else {
          logger.error('Error fetching current commit tracking:', response.status, response.data);
          break;
        }
      } while (lastEvaluatedKey != null);

      trackingDataBySilo[silo.silo_id] = {
        region: silo.region,
        data: trackingData
      };
    } catch (error) {
      logger.error(`Error fetching current commit tracking from ${endpoint}:`, error);
      trackingDataBySilo[silo.silo_id] = {
        region: silo.region,
        error: error instanceof Error ? error.message : String(error)
      };
    }
  }

  return Object.keys(trackingDataBySilo).length > 0 ? trackingDataBySilo : null;
};
