import axios from 'axios';
import { getLogger } from 'src/utils/logger';

const logger = getLogger();

export const fetchLatestNcgVersion = async () => {
  const NCG_VERSION_URL = process.env.REACT_APP_NCG_VERSION_URL;

  if (!NCG_VERSION_URL) {
    logger.error('NCG_VERSION_URL is not defined in the environment');
    return null;
  }

  try {
    const response = await axios.get(NCG_VERSION_URL);

    if (response.status >= 200 && response.status < 300 && response.data && response.data.ncg_version) {
      return response.data.ncg_version;
    } else {
      logger.error('Error fetching NCG version:', response.status, response.data);
      return null;
    }
  } catch (error) {
    logger.error('Error fetching NCG version:', error);
    return null;
  }
};
