import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, useNavigate, useLocation, Navigate, Outlet } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import DeploymentHistoryPage from './pages/DeploymentHistoryPage';
import LatestCommitPage from './pages/LatestCommitPage';
import TrackCommitPage from './pages/TrackCommit';

import {
  AppLayout,
  ContentLayout,
  Header,
  SideNavigation,
  Link
} from '@amzn/awsui-components-react/polaris';



const getHeaderContent = (pathname: string) => {
  const currentPath = pathname.split('/').pop();
  switch (currentPath) {
    case 'dashboard':
      return {
        header: 'Dashboard',
        containerDescription: 'View Commit Age or Track a Commit',
      };
    case 'deployment-history':
      return {
        header: 'Get Deployment History',
        containerDescription: 'View deployment history for the last 5 deployments',
      };
    case 'commit-tracker':
      return {
        header: 'Latest Commit Tracker',
        containerDescription: 'Track the latest commit',
      };
    case 'track-a-commit':
      return {
        header: 'Track a Commit',
        containerDescription: 'Track any commit',
      };
    default:
      return {
        header: 'Unrecognized Path',
        containerDescription: 'Unrecognized Path',
      };
  }
};


const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeHref, setActiveHref] = useState(location.pathname);

  useEffect(() => {
    setActiveHref(location.pathname);
  }, [location.pathname]);
  const handleFollow = (event: any) => {
    event.preventDefault();
    const newHref = event.detail.href;
    setActiveHref(newHref);
    navigate(newHref);
  };
  const headerContent = getHeaderContent(location.pathname);
  return (
    <AppLayout
      navigation={
        <SideNavigation
          header={{ href: '#', text: 'EnSiloPedia' }}
          items={[
            { type: 'link', text: 'Dashboard', href: '/dashboard' },
            { type: 'link', text: 'Get Deployment History', href: '/deployment-history' },
            { type: 'link', text: 'Latest Commit Tracker', href: '/commit-tracker' },
            { type: 'link', text: 'Track a Commit', href: '/track-a-commit' },
            { type: 'divider' },
            {
              type: 'link',
              text: 'Documentation',
              href: 'https://quip-amazon.com/4pUdAdOk0aHo/En-Silo-Pedia-',
              external: true,
            },
          ]}
          activeHref={activeHref}
          onFollow={handleFollow}
        />
      }
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              info={<Link variant="info">Info</Link>}
              description={headerContent.containerDescription}
            >
              {headerContent.header}
            </Header>
          }
        >
          <div>
            <Outlet />
          </div>
        </ContentLayout>
      }
    />
  );
};


const Router = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'deployment-history', element: <DeploymentHistoryPage /> },
        { path: 'commit-tracker', element: <LatestCommitPage /> },
        { path: 'track-a-commit', element: <TrackCommitPage /> },
        { path: '*', element: <div>Not Found or No Permission</div> },
      ],
    },
  ]);
  
  return (
      <RouterProvider router={router} />
  );
};


export default Router;