import { Auth } from '@aws-amplify/auth';

import config from '../public/config.json';

const oauth = {
  domain: config.USER_POOL_DOMAIN,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: window.location.protocol + '//' + window.location.host,
  redirectSignOut: window.location.protocol + '//' + window.location.host,
  responseType: 'code',
  options: {
    AdvancedSecurityDataCollectionFlag: false,
  },
};


const amplify = {
  Auth : {
    // REQUIRED - Amazon Cognito Region
    region: config.USER_POOL_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.USER_POOL_APP,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: `${window.location.hostname}`,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      secure: true,
    },

    oauth: oauth,
  },
  Analytics: {
    disabled: true,
  },
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: config.API_ENDPOINT,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
    ],
  },
};

export default amplify;
