import axios, { AxiosResponse } from 'axios';
import { getLogger } from 'src/utils/logger';

const logger = getLogger();

const ASCI_API_URL = process.env.ASCI_API_URL;

export const sendRequestToASCI = async (commit: string): Promise<string | null> => {
  const commitId = commit;
  try {
    const url = `${ASCI_API_URL}?repository=GitFarm&repoChangeId=${commitId}&getFiles=false&Operation=getChangesById&ContentType=JSON`;
    const response: AxiosResponse<any> = await axios.post(url);
    const data = response.data;

    if (data?.getChangesByIdResponse?.getChangesByIdResult?.changes?.length > 0) {
      const firstChange = data.getChangesByIdResponse.getChangesByIdResult.changes[0];
      const firstRecordedDate = firstChange?.recordedDate ?? null;
      if (firstRecordedDate) {
        logger.log(firstRecordedDate);
        return firstRecordedDate;
      }
    }
    
    logger.error('Unexpected response format from ASCI API');
    return null;
  } catch (error) {
    logger.error('Error fetching data from ASCI API:', error);
    return null;
  }
};

export const getCommitDescription = async (commit: string): Promise<string | null> => {
  const commitId = commit;
  try {
    const url = `${ASCI_API_URL}?repository=GitFarm&repoChangeId=${commitId}&getFiles=false&Operation=getChangesById&ContentType=JSON`;
    const response: AxiosResponse<any> = await axios.post(url);
    const data = response.data;

    if (data?.getChangesByIdResponse?.getChangesByIdResult?.changes?.length > 0) {
      const firstChange = data.getChangesByIdResponse.getChangesByIdResult.changes[0];
      const description = firstChange?.description ?? null;
      
      if (description) {
        // Remove any newline characters and trim whitespace
        const cleanedDescription = description.replace(/\n/g, ' ').trim();
        logger.log(cleanedDescription);
        return cleanedDescription;
      }
    }
    
    logger.error('Unexpected response format from ASCI API');
    return null;
  } catch (error) {
    logger.error('Error fetching data from ASCI API:', error);
    return null;
  }
};