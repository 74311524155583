import * as React from "react";
import Cards from "@cloudscape-design/components/cards";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { Popover } from "@amzn/awsui-components-react/polaris";
import PropertyFilter from "@cloudscape-design/components/property-filter";
import { PropertyFilterProps } from "@cloudscape-design/components/property-filter";
import { StoreData, HeatMapProps } from '../types';
import { useSilos } from '../hooks/useSilos';
import { useStores } from '../hooks/useStores';
import { useCommitTracking } from '../hooks/useCommitTracking';

const itemsPerPage = 6;

export default ({ commitTrackingData, getStoreCommitInfo }: HeatMapProps) => {
  const { silos, filteredSilos, setFilteredSilos } = useSilos();
  const { storesData, filteredStoresData, setFilteredStoresData } = useStores(silos, itemsPerPage);
  const processedCommitTrackingData = useCommitTracking(commitTrackingData);

  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const [pagesCount, setPagesCount] = React.useState(0);
  const [filterQuery, setFilterQuery] = React.useState<PropertyFilterProps.Query>({ tokens: [], operation: 'and' });

  React.useEffect(() => {
    setPagesCount(Math.ceil(filteredSilos.length / itemsPerPage));
  }, [filteredSilos]);

  const handleSearch = (query: PropertyFilterProps.Query) => {
    setFilterQuery(query);
    if (query.tokens.length === 0) {
      setFilteredSilos(silos);
      setFilteredStoresData(storesData);
      setPagesCount(Math.ceil(silos.length / itemsPerPage));
    } else {
      const [matchingSilos, matchingStores, selectedSilos, selectedStores] = [new Set<string>(), new Set<string>(), new Set<string>(), new Set<string>()];
      query.tokens.forEach(token => token.propertyKey === 'silo' ? selectedSilos.add(token.value) : token.propertyKey === 'store' && selectedStores.add(token.value));
      if (query.operation === 'and') {
        silos.forEach(silo => {
          if (selectedSilos.size === 0 || selectedSilos.has(silo)) {
            const filteredStores = (storesData[silo] || []).filter(store => selectedStores.size === 0 || selectedStores.has(store));
            if (filteredStores.length > 0) {
              matchingSilos.add(silo);
              filteredStores.forEach(store => matchingStores.add(store));
            }
          }
        });
      } else {
        silos.forEach(silo => {
          if (selectedSilos.has(silo)) {
            matchingSilos.add(silo);
            (storesData[silo] || []).forEach(store => matchingStores.add(store));
          }
        });
        Object.entries(storesData).forEach(([silo, stores]) => {
          stores.forEach(store => {
            if (selectedStores.has(store)) {
              matchingSilos.add(silo);
              matchingStores.add(store);
            }
          });
        });
      }
      const newFilteredStoresData: Record<string, string[]> = {};
      matchingSilos.forEach(silo => {
        const filteredStores = (storesData[silo] || []).filter(store => matchingStores.has(store));
        if (filteredStores.length > 0) newFilteredStoresData[silo] = filteredStores;
      });
      setFilteredSilos(Array.from(matchingSilos));
      setFilteredStoresData(newFilteredStoresData);
      setPagesCount(Math.ceil(matchingSilos.size / itemsPerPage));
    }
    setCurrentPageIndex(1);
  };  
          
  const filterProperties: PropertyFilterProps.FilteringProperty[] = [
    {
      key: 'silo',
      operators: ['='],
      propertyLabel: 'Silo',
      groupValuesLabel: 'Silo values'
    },
    {
      key: 'store',
      operators: ['='],
      propertyLabel: 'Store',
      groupValuesLabel: 'Store values'
    }
  ];
  
  const paginatedItems = filteredSilos.slice(
    (currentPageIndex - 1) * itemsPerPage,
    currentPageIndex * itemsPerPage
  );
        
  return (
    <>
      <div style={{ padding: "0 10px", marginBottom: "20px" }}>
        <PropertyFilter
          onChange={({ detail }) => handleSearch(detail)}
          query={filterQuery}
          filteringProperties={filterProperties}
          filteringOptions={[
            ...silos.map(silo => ({ 
              propertyKey: 'silo',
              value: silo,
              label: silo
            })),
            ...Object.values(storesData).reduce((acc, stores) => [...acc, ...stores], [])
              .map(store => ({ 
                propertyKey: 'store',
                value: store,
                label: store
              }))
          ]}
          i18nStrings={{
            filteringPlaceholder: "Search silos and stores",
            filteringAriaLabel: "Filter silos and stores",
            groupValuesText: "Values",
            groupPropertiesText: "Properties",
            operationAndText: "and",
            operationOrText: "or",
            clearFiltersText: "Clear filters",
            editTokenHeader: "Edit filter",
            propertyText: "Property",
            operatorText: "Operator",
            valueText: "Value",
            cancelActionText: "Cancel",
            applyActionText: "Apply",
            allPropertiesLabel: "All properties",
            tokenLimitShowMore: "Show more",
            tokenLimitShowFewer: "Show fewer",
            clearAriaLabel: "Clear",
            removeTokenButtonAriaLabel: (token) => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
            operatorEqualsText: "equals",
          }}
        />
      </div>
      <Cards
        ariaLabels={{
          itemSelectionLabel: (e, t) => `select ${t.name}`,
          selectionGroupLabel: "Item selection",
        }}
        cardDefinition={{
          header: (item) => <p>{item.name}</p>,
          sections: [
            {
              id: "description",
              header: "",
              content: (item) => (
                <ColumnLayout>
                  {filteredStoresData[item.name]?.map((store, index) => {
                    const storeInfo = processedCommitTrackingData[store];
                    
                    let storeStyle: React.CSSProperties = {
                      padding: "5px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      background: "white" // Default background
                    };
        
                    if (storeInfo) {
                      const commitInfo = getStoreCommitInfo.find((info) => info.label === storeInfo.commitId);
                      if (commitInfo) {
                        storeStyle.background = commitInfo.color ?? "white"; // Fallback to white if color is undefined
                      }
                    }
        
                    return (
                      <div key={`${currentPageIndex}-${index}-${store}`} style={{ position: "relative" }}>
                        <Popover
                          content={
                            storeInfo
                              ? `Commit ID: ${storeInfo.commitId}`
                              : "No commit info"
                          }
                          position="top"
                          dismissAriaLabel="Close"
                        >
                          <div style={storeStyle}>{store}</div>
                        </Popover>
                      </div>
                    );
                  }) || <div>No matching stores</div>}
                </ColumnLayout>
              ),
            },
          ],
        }}
        cardsPerRow={[{ minWidth: 0, cards: 6 }]}
        items={paginatedItems.map((silo) => ({
          name: silo,
          alt: `Silo ${silo}`,
          description: `Stores for ${silo}`,
        }))}
        loadingText="Loading silos and stores"
        stickyHeader
        variant="full-page"
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No results found</b>
              <Button
                onClick={() => {
                  setFilteredSilos(silos);
                  setFilteredStoresData(storesData);
                  setCurrentPageIndex(1);
                  setPagesCount(Math.ceil(silos.length / itemsPerPage));
                }}
              >
                Clear all searches
              </Button>
            </SpaceBetween>
          </Box>
        }
        pagination={
          filteredSilos.length > 0 ? (
            <Pagination
              currentPageIndex={currentPageIndex}
              pagesCount={pagesCount}
              onChange={({ detail }) => {
                setCurrentPageIndex(detail.currentPageIndex);
              }}
            />
          ) : null
        }
      />
    </>
  );
};
