//This setup wraps console.log and other logging methods in the logger utility. In production, it switches to a nullLogger that suppresses all logs so as to prevent things from being printed to console in prod



const isProd = process.env.REACT_APP_DEV_ENVIRONMENT === 'false';

const nullLogger = {
  log: () => {},
  warn: () => {},
  error: () => {},
  info: () => {},
};

const consoleLogger = {
  log: (...args) => console.log(...args),
  warn: (...args) => console.warn(...args),
  error: (...args) => console.error(...args),
  info: (...args) => console.info(...args),
};

export const getLogger = () => (isProd ? nullLogger : consoleLogger);
