import * as React from "react";
import Cards from "@cloudscape-design/components/cards";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { sendRequestToASCI } from '../api/AsciApi';
import { getLogger } from 'src/utils/logger';
import { SiloStoreCardsProps } from '../types';
import { useSilos } from '../hooks/useSilos';
import { useStores } from '../hooks/useStores';
import { useCommitTracking } from '../hooks/useCommitTracking';

const logger = getLogger();


const SiloStoreCards: React.FC<SiloStoreCardsProps> = ({ commitTrackingData, commitTime }) => {
  const { silos } = useSilos();
  const { storesData } = useStores(silos, 6);
  const processedCommitTrackingData = useCommitTracking(commitTrackingData);
  
  const [asciCallQueue, setAsciCallQueue] = React.useState<string[]>([]);
  const [asciCommitTimes, setAsciCommitTimes] = React.useState<Record<string, number | null>>({});
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const [pagesCount, setPagesCount] = React.useState(0);
  
  const itemsPerPage = 6;

  React.useEffect(() => {
    setPagesCount(Math.ceil(silos.length / itemsPerPage));
  }, [silos]);

  //making async asci calls
  React.useEffect(() => {
    const uniqueCommitIds = Array.from(new Set(Object.values(processedCommitTrackingData).map(store => store.commitId)));
    setAsciCallQueue(uniqueCommitIds);
  }, [processedCommitTrackingData]);

  const makeASCICall = async (commitId: string, delay: number) => new Promise<void>(resolve => 
    setTimeout(async () => {
      try {
        const commitTime = await sendRequestToASCI(commitId);
        setAsciCommitTimes(prevTimes => ({...prevTimes, [commitId]: commitTime !== null ? Number(commitTime) : null}));
      } catch (error) {
        setAsciCommitTimes(prevTimes => ({...prevTimes, [commitId]: null}));
        logger.error(`Error fetching ASCI commit time for ${commitId}:`, error);
      }
      resolve();
    }, delay)
  );

  // Queue for ASCI calls
  React.useEffect(() => {
    const processQueue = async () => {
      const delay = 2500;
      while (asciCallQueue.length > 0) {
        const commitId = asciCallQueue.shift();
        if (commitId) {
          await makeASCICall(commitId, delay);
        }
      }
    };
    if (asciCallQueue.length > 0) {
      processQueue();
    }
  }, [asciCallQueue]);

  const getStoreColor = (storeId: string) => {
    const storeData = processedCommitTrackingData[storeId];
    if (!storeData) return ''; // No commit info for store

    const { commitId } = storeData;
    const asciCommitTime = asciCommitTimes[commitId];

    if (asciCommitTime === null || asciCommitTime === undefined) {
      return 'orange'; // ASCI commit time is null or undefined
    }

    if (commitTime === null) {
      return ''; // commitTime not set, can't compare
    }

    return asciCommitTime < commitTime ? 'red' : 'green';
  };

  // Paginating the cards
  const paginatedItems = silos
    ? silos.slice((currentPageIndex - 1) * itemsPerPage, currentPageIndex * itemsPerPage)
    : [];

  return (
    <Cards
      ariaLabels={{
        itemSelectionLabel: (e, t) => `select ${t.name}`,
        selectionGroupLabel: "Item selection"
      }}
      cardDefinition={{
        header: (item) => <p>{item.name}</p>,
        sections: [
          {
            id: "description",
            header: "",
            content: (item) => (
              <ColumnLayout>
                {storesData[item.name]?.map((store, index) => (
                  <div 
                    key={index} 
                    style={{ 
                      background: getStoreColor(store),
                      padding: '5px',
                      borderRadius: '4px'
                    }}
                  >
                    {store}
                  </div>
                )) || <div>Loading Stores</div>}
              </ColumnLayout>
            )
          }
        ]
      }}
      cardsPerRow={[{ minWidth: 0, cards: 6 }]}
      items={paginatedItems.map(silo => ({
        name: silo,
        alt: `Silo ${silo}`,
        description: `Stores for ${silo}`,
      }))}
      loadingText="Loading silos and stores"
      stickyHeader
      variant="full-page"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>Loading Stores</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      }
      pagination={
        silos && silos.length > 0 ? (
          <Pagination
            currentPageIndex={currentPageIndex}
            pagesCount={pagesCount}
            onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
          />
        ) : null
      }
    />
  );
};

export default SiloStoreCards;