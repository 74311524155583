import React, { useState, useEffect } from 'react';
import Container from "@amzn/awsui-components-react/polaris/container";
import Autosuggest from "@amzn/awsui-components-react/polaris/autosuggest";
import Button from "@amzn/awsui-components-react/polaris/button";
import { sendRequestToASCI } from '../api/AsciApi';
import { fetchLatestNcgVersion } from '../api/NCGApi';
import { fetchCurrentCommitTracking } from '../api/NCDApi';
import SiloStoreCards from "../components/Cards";
import { getLogger } from 'src/utils/logger';
import Box from "@cloudscape-design/components/box";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { TrackingDataBySilo } from '../types';

const logger = getLogger();

const TrackCommitPage = () => {
  const [searchInput, setSearchInput] = useState('');
  const [recordedDate, setRecordedDate] = useState<number | null>(null);
  const [commitTrackingData, setCommitTrackingData] = useState<TrackingDataBySilo | null>(null);
  const [commitOptions, setCommitOptions] = useState<{ value: string }[]>([]);
  const [latestNcgVersion, setLatestNcgVersion] = useState<string | null>(null);

  useEffect(() => {
    const fetchInitialCommitTime = async () => {
      try {
        const fetchedNcgVersion = await fetchLatestNcgVersion();
        if (fetchedNcgVersion) {
          setLatestNcgVersion(fetchedNcgVersion);
          const commitTime = await sendRequestToASCI(fetchedNcgVersion);
          if (commitTime) {
            setRecordedDate(+commitTime);
          } else {
            logger.error('No commit time available for the latest NCG version');
          }
        } else {
          logger.error('Failed to fetch the latest NCG version');
        }
      } catch (error) {
        logger.error('Error fetching initial commit time:', error);
      }
    };

    const fetchData = async () => {
      try {
        const fetchedTrackingData = await fetchCurrentCommitTracking();
        if (fetchedTrackingData) {
          setCommitTrackingData(fetchedTrackingData);
          const options = Object.values(fetchedTrackingData).reduce((acc, siloData) => {
            if (siloData.data) {
              siloData.data.forEach(config => {
                if (config.ncg_commit_id && !acc.some(option => option.value === config.ncg_commit_id)) {
                  acc.push({ value: config.ncg_commit_id });
                }
              });
            }
            return acc;
          }, [] as { value: string }[]);
          setCommitOptions(options);
        } else {
          logger.error('No valid tracking data available or received null');
        }
      } catch (error) {
        logger.error('Error fetching commit tracking data:', error);
      }
    };    

    fetchInitialCommitTime();
    fetchData();
  }, []);

  const handleSearch = async () => {
    if (searchInput) {
      try {
        const data = await sendRequestToASCI(searchInput);
        if (data) {
          logger.log(data);
          setRecordedDate(+data);
        }
      } catch (error) {
        logger.error('Error fetching data:', error);
      }
    }
  };

  const Legend = () => (
    <Box padding="s">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <div style={{
          width: '20px',
          height: '20px',
          backgroundColor: 'red',
          marginRight: '10px'
        }}></div>
        <span>Commit has not reached the store</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <div style={{
          width: '20px',
          height: '20px',
          backgroundColor: 'green',
          marginRight: '10px'
        }}></div>
        <span>Commit has reached the store</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <div style={{
          width: '20px',
          height: '20px',
          backgroundColor: 'orange',
          marginRight: '10px'
        }}></div>
        <span>Store not in FullCD</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{
          width: '20px',
          height: '20px',
          border: '1px solid #ccc',
          marginRight: '10px'
        }}></div>
        <span>No commit info for store</span>
      </div>
    </Box>
  );
  return (
    <Container>
      <br />
      {latestNcgVersion && (
        <Alert type="info" header="Default View">
          By default, the heatmap shows information for the latest regression tested commit: {latestNcgVersion}
        </Alert>
      )}
      <br />
      <br />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '60%' }}>
          <Autosuggest
            value={searchInput}
            onChange={e => setSearchInput(e.detail.value)}
            options={commitOptions}
            ariaLabel="Enter commit ID"
            placeholder="Enter commit ID"
            empty="No matches found"
          />
        </div>
        <div style={{ width: '40%', marginLeft: '30px'}}>
          <Button variant="primary" onClick={handleSearch}>Search</Button>
        </div>
      </div>
      <br />
      <Legend />
      <SiloStoreCards commitTrackingData={commitTrackingData} commitTime={recordedDate} />
    </Container>
  );
};

export default TrackCommitPage;