import React, { useEffect, useState } from 'react';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import config from './amplify';
import { Spinner } from '@amzn/awsui-components-react/polaris';
import Router from './Router';

Amplify.configure(config);


const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setLoading(false);
      })
      .catch(() => {
        Auth.federatedSignIn({ customProvider: 'Amazon' }).catch((err) => {
          console.log('Error with federated sign-in:', err);
          setLoading(false);
        });
      });
  }, []);
  return (
    <div className="awsui-visual-refresh">
      {loading ?
        <div>
          <Spinner /> Loading...
        </div>
      : 
        <Router />
      }
    </div>
  );
};


export default App;