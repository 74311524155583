import { useState, useEffect } from 'react';
import { fetchSilos } from '../api/NCDApi';

export const useSilos = () => {
  const [silos, setSilos] = useState<string[]>([]);
  const [filteredSilos, setFilteredSilos] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const silosData = await fetchSilos();
      setSilos(silosData);
      setFilteredSilos(silosData);
    };
    fetchData();
  }, []);

  return { silos, filteredSilos, setFilteredSilos };
};