import Container from "@amzn/awsui-components-react/polaris/container";
import React, { useEffect, useState } from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import { fetchCurrentCommitTracking } from '../api/NCDApi';
import PieChart from "@cloudscape-design/components/pie-chart";
import Box from "@cloudscape-design/components/box";
import { differenceInDays } from 'date-fns';
import Popover from "@amzn/awsui-components-react/polaris/popover";
import { getLogger } from 'src/utils/logger';
import HeatMap from "../components/HeatMap";
import { getCommitDescription } from "../api/AsciApi";
import CopyToClipboard from "@cloudscape-design/components/copy-to-clipboard";
import { ChartDataItem, TrackingDataBySilo } from '../types';

const logger = getLogger();

const DashboardPage = () => {
  const [commitTrackingData, setCommitTrackingData] = useState<TrackingDataBySilo | null>(null);
  const [chartData, setChartData] = useState<ChartDataItem[]>([]);
  const [commitDescriptions, setCommitDescriptions] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCurrentCommitTracking();
        setCommitTrackingData(data);
      } catch (error) {
        console.error('Error fetching commit tracking data:', error);
        setCommitTrackingData(null);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (commitTrackingData) {
      const processedData = processTrackingData(commitTrackingData);
      setChartData(processedData);
    }
  }, [commitTrackingData]);

  const processTrackingData = (trackingDataBySilo: TrackingDataBySilo): ChartDataItem[] => {
    if (!trackingDataBySilo) {
      logger.error('Invalid trackingDataBySilo:', trackingDataBySilo);
      return [];
    }
  
    const currentDate = new Date();
    const commitAgeMap = new Map<string, { age: number, stores: string[], startTime: string }>();
    
    Object.values(trackingDataBySilo).forEach(siloData => {
      if (siloData.data) {
        siloData.data.forEach(config => {
          const { ncg_commit_id, start_time, store_id } = config;
          const commitId = ncg_commit_id || 'UNKNOWN';
          if (start_time) {
            const startTimeDate = new Date(start_time);
            const ageInDays = differenceInDays(currentDate, startTimeDate);
            if (commitAgeMap.has(commitId)) {
              commitAgeMap.get(commitId)!.stores.push(store_id);
            } else {
              commitAgeMap.set(commitId, { age: ageInDays, stores: [store_id], startTime: start_time });
            }
          }
        });
      }
    });
  
    return Array.from(commitAgeMap.entries()).map(([commitId, { age, stores, startTime }]) => {
      let color = '#D13313';
      if (age < 30) {
        color = '#41902C';
      } else if (age >= 30 && age < 90) {
        color = '#67A353';
      } else if (age >= 90 && age < 120) {
        color = '#FF9900';
      } else if (age >= 120 && age < 360) {
        color = '#DC5032';
      }
      
      return {
        label: commitId,
        title: commitId,
        stores: stores,
        description: `${age} days`,
        commitAge: age,
        color: color,
        startTime: startTime,
      };
    });
  };

  useEffect(() => {
    const fetchAllCommitDescriptions = async () => {
      const uniqueCommitIds = Array.from(new Set(chartData.map(item => item.label)));
      for (const commitId of uniqueCommitIds) {
        try {
          const description = await getCommitDescription(commitId);
          setCommitDescriptions(prev => ({
            ...prev,
            [commitId]: description || 'No description available'
          }));
        } catch (error) {
          logger.error(`Error fetching description for commit ${commitId}:`, error);
          setCommitDescriptions(prev => ({
            ...prev,
            [commitId]: 'Error fetching description'
          }));
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    };

    if (chartData.length > 0) {
      fetchAllCommitDescriptions();
    }
  }, [chartData]);

  return (
    <div>
      <Container
        header={
          <Header variant="h2">
            Commit Age Insights
          </Header>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", marginBottom: "30px", gap: "20px" }}>
          <div style={{ width: '50%' }}>
            <PieChart
              data={chartData.map(item => ({ ...item, value: item.stores.length }))}
              i18nStrings={{ filterPlaceholder: "Filter Commit Id" }}
              detailPopoverContent={(datum, sum) => [
                { key: "Number of stores", value: datum.stores.length },
                {
                  key: "Percentage (based on store count)",
                  value: `${((datum.stores.length / sum) * 100).toFixed(0)}%`
                },
                { key: "Commit Age", value: datum.description }
              ]}
              segmentDescription={(datum, storeCountSum) =>
                `${datum.commitAge} days, ${((datum.stores.length / storeCountSum) * 100).toFixed(0)}%`
              }
              ariaDescription="Pie chart showing the age of different commits."
              ariaLabel="Commit Age Pie Chart"
              hideFilter
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No data available</b>
                  <Box variant="p" color="inherit">
                    There is no data available
                  </Box>
                </Box>
              }
              noMatch={
                <Box textAlign="center" color="inherit">
                  <b>No matching data</b>
                  <Box variant="p" color="inherit">
                    There is no matching data to display
                  </Box>
                  <Button>Clear filter</Button>
                </Box>
              }
            />
          </div>
          <div style={{ width: '50%', display: "flex", alignItems: "stretch" }}>
          <Table
            columnDefinitions={[
              {
                id: "commitId",
                header: "Commit ID",
                cell: item => (
                  <Popover
                    content={
                      <div>
                        <p>
                          <strong>Commit ID:</strong>{' '}
                          {item.label}
                          <span style={{ marginLeft: '2px', display: 'inline-block' }}>
                            <CopyToClipboard
                              copyButtonText="Copy"
                              copyErrorText="Commit ID failed to copy"
                              copySuccessText="Commit ID copied"
                              textToCopy={item.label}
                              variant="icon"
                            />
                          </span>
                        </p>
                        <p><strong>Commit Date:</strong> {item.startTime}</p>
                        <p><strong>Description:</strong> {commitDescriptions[item.label] || 'Loading...'}</p>
                      </div>
                    }
                    position="top"
                    dismissAriaLabel="Close"
                  >
                    <div style={{ 
                      cursor: 'pointer', 
                      border: "none", 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      maxWidth: '100%'
                    }}>
                      <span 
                        style={{ 
                          width: '12px', 
                          height: '12px', 
                          backgroundColor: item.color, 
                          display: 'inline-block', 
                          flexShrink: 0,
                          borderRadius: '2px',
                          marginRight: '8px',
                          marginTop: '4px'
                        }} 
                      />
                      <span style={{ 
                        flexGrow: 1,
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        hyphens: 'auto'
                      }}>
                        {item.label}
                      </span>
                    </div>
                  </Popover>
                ),
                width: "40%",
                sortingField: "description",
                isRowHeader: true
              },
              {
                id: "age",
                header: "Age",
                cell: item => item.description,
                width: "20%",
                sortingField: "description"
              },
              {
                id: "storeCount",
                header: "Number of Stores",
                cell: item => (
                  <Popover
                    content={
                      <div>
                        <strong>Stores:</strong>
                        <ul>
                          {item.stores.map((store, index) => (
                            <li key={index}>{store}</li>
                          ))}
                        </ul>
                      </div>
                    }
                    position="top"
                    dismissAriaLabel="Close"
                  >
                    <span style={{ cursor: 'pointer', border: "none" }}>{item.stores.length}</span>
                  </Popover>
                ),
                width: "40%",
                sortingField: "description"
              }
            ]}
            items={chartData}
            loadingText="Loading resources"
            resizableColumns
            variant="embedded"
            empty={
              <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
                <SpaceBetween size="m">
                  <b>No resources</b>
                  <Button>Create resource</Button>
                </SpaceBetween>
              </Box>
            }
          />
          </div>
        </div>

        <HeatMap
          commitTrackingData={commitTrackingData}
          getStoreCommitInfo={chartData}
        />
      </Container>
      <br />
    </div>
  );
};

export default DashboardPage;
